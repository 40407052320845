import { effect, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { combineLatest, filter, firstValueFrom, switchMap, take, tap } from 'rxjs';

import { AmplitudeService } from '@stsm/analytics';
import { AMPLITUDE_DEVICE_ID_PARAM_NAME } from '@stsm/auth/models/signup-params';
import { FeatureToggle, FeatureToggleService } from '@stsm/devtools/services/feature-toggle.service';
import { WEB_APP_URL } from '@stsm/shared/models/web-app-url';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { TargetMarketBaseService } from '@stsm/user/feature/services/target-market-base.service';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { environment } from '../../../environments/environment';
import { TargetMarketRedirectionDialogComponent } from '../../components/target-market-redirection-dialog/target-market-redirection-dialog.component';
import { isHandledDeeplink } from '../../deeplink.util';
import { isWebsiteEntryUrl } from '../../website-entry-url';
import { LocalStorageKey } from '../enums/local-storage-key';

import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class TargetMarketService extends TargetMarketBaseService {
  private _registeredDeeplink: string | undefined;

  constructor(
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _platformModalService: PlatformModalService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _routerStoreFacade: RouterStoreFacade,
    private readonly _amplitudeService: AmplitudeService,
    private readonly _featureTogglesService: FeatureToggleService,
  ) {
    super();

    effect(() => this._analyticsService.setAmplitudeUserProperty('target_market_web', this.targetMarket()));

    combineLatest([this._routerStoreFacade.url$.pipe(filter(Boolean), take(1)), this.authStore.isUserLoggedIn$])
      .pipe(
        take(1),
        switchMap(([initialUrl, isLoggedIn]: [string, boolean]) => {
          const initialRoute = this._registeredDeeplink ?? initialUrl;

          // Don't do anything on the US domain
          if (environment.TARGET_MARKET === 'us' || environment.CONTENT_CREATORS) {
            return VOID;
          }

          if (
            this._featureTogglesService.getFeatureToggleValue({
              featureToggle: FeatureToggle.PREVENT_REDIRECTION_TO_VAIA,
              valueToUseInProduction: false,
            })
          ) {
            return VOID;
          }

          if (!isLoggedIn) {
            return this._showRedirectionDialogIfNecessary({ initialRoute });
          }

          // [Logged in] Once the user becomes available, check whether we have to redirect the user
          return this._userStoreFacade.userAvailable$.pipe(
            tap((user: User) => {
              // Only check the device locale if the user's target market had not yet been set by the mobile app.
              if (isNil(user.targetMarket)) {
                void this._showRedirectionDialogIfNecessary({ initialRoute, shouldUpdateUserOnRedirect: true });
              }

              if (user.targetMarket === 'us') {
                void this._showRedirectionDialog(initialRoute);
              }
            }),
          );
        }),
      )
      .subscribe();

    if (environment.devtoolsEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).preventRedirectionToVaia = (): void => {
        this._featureTogglesService.setFeatureToggle(FeatureToggle.PREVENT_REDIRECTION_TO_VAIA, true, { shouldPersistInStorage: true });
      };
    }
  }

  /**
   * It is not possible to capture the value of an initial web deeplink via the RouterStoreFacade, which is why it needs
   * to be registered here in order to be used in the redirection dialog.
   * @param url the url of the deeplink
   */
  registerDeeplink(url: string): void {
    this._registeredDeeplink = url;
  }

  protected override getLanguageTag(): Promise<string> {
    return Promise.resolve(navigator.language);
  }

  /**
   * Checks the browser's locale to decide whether to forward the user
   * @private
   */
  private async _showRedirectionDialogIfNecessary(options: { initialRoute: string; shouldUpdateUserOnRedirect?: boolean }): Promise<void> {
    if (this._shouldAvoidRedirectionInE2eEnvironment()) {
      return;
    }

    const targetMarketBasedOnLocale = await this.getTargetMarketBasedOnLocale();

    if (targetMarketBasedOnLocale === 'core') {
      return;
    }

    if (options.shouldUpdateUserOnRedirect) {
      this.updateUserTargetMarketRequest$.next('us');
    }

    return this._showRedirectionDialog(options.initialRoute);
  }

  private async _showRedirectionDialog(initialRoute: string): Promise<void> {
    await firstValueFrom(this.translationService.translationsReady$);

    const amplitudeDeviceId = await this._amplitudeService.getDeviceId();

    const kind: 'DEV' | 'PROD' = environment.WEBAPP_URL.includes('test') ? 'DEV' : 'PROD';

    let url: URL;

    if (isWebsiteEntryUrl(initialRoute) || isHandledDeeplink(initialRoute)) {
      url = new URL(`https://${WEB_APP_URL.VAIA[kind]}${initialRoute}`);
    } else {
      url = new URL(`https://${WEB_APP_URL.VAIA[kind]}${window.location.pathname}${window.location.search}`);
    }

    if (!isNil(amplitudeDeviceId) && !url.searchParams.has(AMPLITUDE_DEVICE_ID_PARAM_NAME)) {
      url.searchParams.append(AMPLITUDE_DEVICE_ID_PARAM_NAME, amplitudeDeviceId);
    }

    this._platformModalService
      .schedule({
        component: TargetMarketRedirectionDialogComponent,
        data: <InputPropertiesOf<TargetMarketRedirectionDialogComponent>>{
          redirectionUrl: url.toString(),
        },
        allowBackdropDismiss: false,
        disposeOnNavigation: false,
      })
      .subscribe();
  }

  private _shouldAvoidRedirectionInE2eEnvironment(): boolean {
    return environment.name === 'E2E' && isNil(localStorage.getItem(LocalStorageKey.ALLOW_VAIA_REDIRECTION_IN_E2E_ENVIRONMENT));
  }
}
