import { isNil } from 'lodash-es';

import { TargetMarket } from '@stsm/shared/models/target-market';

/**
 * countries where "StudySmarter" is used as company name
 */
const CORE_MARKETS: string[] = ['DE', 'AT', 'CH', 'GB', 'FR'];

/**
 * returns the TargetMarket from the language tag
 *
 * @param languageTag the language tag, e.g. 'en-US'
 * @private
 */
export function resolveTargetMarketFromLanguageTag(languageTag: string): TargetMarket {
  const language = languageTag.split('-')[0];
  const country = languageTag.match(/[^a-zA-Z]([A-Z]{2})/)?.[1];

  console.log(`[Target Market] language: ${language} | country: ${country}`);

  if (isNil(language) || language.length === 0) {
    return 'core';
  }

  if (isNil(country)) {
    return ['de', 'fr', 'en'].includes(language) ? 'core' : 'us';
  }

  return CORE_MARKETS.includes(country) ? 'core' : 'us';
}
