import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, InputSignal, OnInit, signal, WritableSignal } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';

import { AmplitudeService } from '@stsm/analytics';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { ButtonComponent } from '@stsm/ui-components/button';
import { SpinnerComponent } from '@stsm/ui-components/spinner';

import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'app-target-market-redirection-dialog',
  templateUrl: './target-market-redirection-dialog.component.html',
  styleUrls: ['./target-market-redirection-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, AsyncPipe, TranslatePipe, SpinnerComponent],
})
export class TargetMarketRedirectionDialogComponent implements OnInit {
  redirectionUrl: InputSignal<string> = input.required<string>();

  protected readonly stsmLogoSource$: Observable<LogoSource> = this._themingStore.darkThemeActive$.pipe(
    map((isDarkTheme: boolean) => (isDarkTheme ? LogoSource.STUDYSMARTER_WHITE : LogoSource.STUDYSMARTER)),
  );

  protected readonly vaiaLogoSource$: Observable<LogoSource> = this._themingStore.darkThemeActive$.pipe(
    map((isDarkTheme: boolean) => (isDarkTheme ? LogoSource.VAIA_WHITE : LogoSource.VAIA)),
  );

  protected readonly isLoading: WritableSignal<boolean> = signal(false);

  constructor(
    private readonly _themingStore: ThemingStore,
    private readonly _analyticsService: AnalyticsService,
    private readonly _amplitudeService: AmplitudeService,
  ) {}

  ngOnInit(): void {
    this._analyticsService.trackEvent({ action: 'vaia_transit_popup_view' });
  }

  protected async redirect(): Promise<void> {
    this.isLoading.set(true);
    this._analyticsService.trackEvent({ action: 'vaia_transit_popup_click' });

    if (environment.reportEventsToAmplitude) {
      await firstValueFrom(this._amplitudeService.waitForEventToBeHandled('vaia_transit_popup_click'));
    }

    window.open(this.redirectionUrl(), '_self');
  }
}
