import { registerPlugin, WebPlugin } from '@capacitor/core';

export interface AppNamePlugin {
  getAppName(): Promise<{ appName: string | undefined }>;
}

export class AppNamePluginWeb extends WebPlugin implements AppNamePlugin {
  getAppName(): Promise<{ appName: string | undefined }> {
    return Promise.resolve({ appName: undefined });
  }
}

export const AppNamePlugin = registerPlugin<AppNamePlugin>('AppNamePlugin', {
  web: () => new AppNamePluginWeb(),
});
