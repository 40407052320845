<div class="logos-container">
  <div class="logo-wrapper">
    <img alt="" [src]="stsmLogoSource$ | async" />
  </div>
  <i class="app-icon-chevron-forward"></i>
  <div class="logo-wrapper">
    <img class="vaia-logo" alt="" [src]="vaiaLogoSource$ | async" />
  </div>
</div>

<div class="heading">
  <span>🎉</span>
  {{ "REDIRECTION_DIALOG.HEADING" | translate }}
  <span>🚀</span>
</div>

<p>{{ "REDIRECTION_DIALOG.REDIRECTION_MESSAGE" | translate }}</p>

<button uiButton size="lg" [disabled]="isLoading()" (click)="redirect()">
  @if (isLoading()) {
    <ui-spinner slot="start" />
  }
  {{ "REDIRECTION_DIALOG.REDIRECT_BUTTON" | translate }}
</button>
