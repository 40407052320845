export enum WebDeeplink {
  ENTRY = 'entry',
  PREMIUM = 'premium',
  MONDIA = 'mondia',
  OFFER = 'offer',
}

export function isHandledDeeplink(url: string): boolean {
  return Object.values(WebDeeplink).some((deeplink: string) => url.includes(deeplink));
}
